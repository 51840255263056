import React, { useContext, useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-flexbox-grid";
import axios from "axios";
import ContentBlock from "components/content-block";

import { ThankYouModal } from "components/modals/ThankYou";
import { Layout, Seo } from "components";
import { appContext } from "providers/appProvider";
import { unitedStates } from "libs/utilityHelper";

// import page style
import "./get-in-touch.scss";

// const REST_URL = "https://sprixdirect-rep-emailer.firebaseio.com/contacts.json";
// const REST_URL = "https://s1529zpze2.execute-api.ap-south-1.amazonaws.com/dev/req-rep-email";
const REST_URL =
  "/apis/email/request-rep";

const RepSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  practiceName: Yup.string().required(),
  email: Yup.string().email().required(),
  state: Yup.string().required(),
});
const pageTitle = "Get In Touch";
const pageDescription = "";

const initialValues = {
  firstName: "",
  lastName: "",
  practiceName: "",
  specialty: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
  phone: "",
};

const FormContainer = (props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RepSchema}
      onSubmit={async (values, actions) => {
        const dataToSend = {};
        dataToSend.data = {
          brand: "SPRIX",
          first_name: values.firstName,
          last_name: values.lastName,
          practice_name: values.practiceName,
          specialty: values.specialty,
          email: values.email,
          street_address: values.address1,
          street_address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zipCode,
          phone: values.phone,
          // _subject: "Sprix Contant Message Received",
          // _format: "plain",
        };

        const config = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        const result = await axios.post(REST_URL, dataToSend, config);
        if (result.status === 200) {
          props.submittedForm();
        } else {
          alert(
            "Sorry, there has been an error. Please send your message to jbenson@deerfieldagency.com"
          );
        }
        actions.resetForm(initialValues);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form id="request-sales-rep-form" className="request-rep-form">
          <Row>
            <Col xs={12}>
              <label htmlFor="firstName">First Name*</label>
            </Col>
            <Col xs={12}>
              <Field
                type="text"
                name="firstName"
                className={touched.firstName && errors.firstName ? "error" : ""}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="lastName">Last Name*</label>
            </Col>
            <Col xs={12}>
              <Field
                type="text"
                name="lastName"
                className={touched.lastName && errors.lastName ? "error" : ""}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="practiceName">Practice Name*</label>
            </Col>
            <Col xs={12}>
              <Field
                type="text"
                name="practiceName"
                className={
                  touched.practiceName && errors.practiceName ? "error" : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label
                className="text-input-label select-lbl"
                htmlFor="specialty"
              >
                Specialty
              </label>
            </Col>
            <Col xs={12}>
              <Field as="select" name="specialty">
                <option value="">Select</option>
                <option value="Primary Care Pain">Primary Care Pain</option>
                <option value="Dentistry">Dentistry</option>
                <option value="Anesthesiology">Anesthesiology</option>
                <option value="Orthopedics">Orthopedics</option>
                <option value="OB/GYN">OB/GYN</option>
                <option value="Urology">Urology</option>
                <option value="Other">Other</option>
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="email">Email Address*</label>
            </Col>
            <Col xs={12}>
              <Field
                type="text"
                name="email"
                className={touched.email && errors.email ? "error" : ""}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="address1">Address 1</label>
            </Col>
            <Col xs={12}>
              <Field type="text" name="address1" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="address2">Address 2 (optional)</label>
            </Col>
            <Col xs={12}>
              <Field type="text" name="address2" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="city">City</label>
            </Col>
            <Col xs={12}>
              <Field type="text" name="city" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="state">State*</label>
            </Col>
            <Col xs={12}>
              <Field
                as="select"
                name="state"
                className={touched.state && errors.state ? "error" : ""}
              >
                {unitedStates.map((st) => (
                  <option value={st.value}>{st.label}</option>
                ))}
              </Field>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="zipCode">ZIP Code</label>
            </Col>
            <Col xs={12}>
              <Field type="text" name="zipCode" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label htmlFor="phone">Phone Number</label>
            </Col>
            <Col xs={12}>
              <Field type="text" name="phone" />
            </Col>
          </Row>
          <div className="button-container">
            <Row>
              <Col xs={12} md={5} className="btn-send">
                <button type="submit">Send</button>
              </Col>
              <Col xs={12} md={7} className="privacy-container">
                <div>
                  <p className="underline">Privacy</p>
                  <p>
                    Assertio respects your right to have your personal
                    information kept private. We may use this information to
                    send you materials on our products and services that may be
                    of interest to you. By submitting this registration, you
                    understand that you are giving Assertio and its affiliates
                    and business partners permission to use your personal
                    information to provide you with information and offers
                    related to support programs, treatment options, and services
                    provided by Assertio. You understand that Assertio and its
                    contractors will not sell or rent your personal information.
                    For more information about how Assertio will use and protect
                    your personal information, please read the Privacy Policy.
                  </p>
                  <p>
                    For more information about how Assertio will use and protect
                    your personal information, please read the{" "}
                    <a
                      href={"https://www.assertiotx.com/privacy-policy/"}
                      target={"_blank"}
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const SprixDirect = () => {
  return (
    <div className="sprix-direct-container">
      <ContentBlock className="larger-width">
        <h2 id="sprix-direct" className="sprix no-padding-bottom">
          Prescribe to Scripts Rx <br />
          for no copay card required
        </h2>
        <div className="prescribing-container">
          <p className="center prescribe-copy">
            <strong>Scripts Rx (formerly Mall Pharmacy)</strong>
          </p>
          <p className="center prescribe-copy">
            1815 S. Meyers Road, Suite 100
          </p>
          <p className="center prescribe-copy">Oakbrook Terrace, IL 60181</p>
          <p className="center prescribe-copy">
            <strong>NCPDP/NABP:</strong> 1468481
          </p>
          <p className="center prescribe-copy">
            <strong>NPI:</strong> 110 498 2230
          </p>
          <p className="center prescribe-copy">
            <strong>Questions, call:</strong> (844) 977-7749
          </p>
          <p className="center prescribe-copy">
            <strong>Fax:</strong> (844) 794-7275
          </p>
        </div>
      </ContentBlock>
    </div>
  );
};

const ContactSection = () => {
  return (
    <div className="contact-section" id="contact">
      <ContentBlock>
        <Row>
          <Col xs={0} md={1} />
          <Col xs={12} md={4}>
            <h2>Contact Assertio</h2>
            <h3>100 South Saunders Road</h3>
            <h3>Suite 300</h3>
            <h3>Lake Forest, IL 60045</h3>
          </Col>
          <Col xs={1} md={1} />
          <Col xs={12} md={6} className="no-padding">
            <h2 className="smaller">
              FOR SPRIX PRODUCT INFORMATION OR TO REPORT AN ADVERSE REACTION TO
              ASSERTIO:
            </h2>
            <p className="no-padding-bottom">
              <span>PHONE:</span> 866-458-6389
            </p>
            <p className="no-padding-bottom">
              <span>EMAIL:</span> medinfo@assertiotx.com
            </p>
            <h4>To report adverse events to the FDA:</h4>
            <p className="no-padding-bottom">
              <span>PHONE:</span> 1-800-FDA-1088
            </p>
            <p className="no-padding-bottom">
              <a href="http://www.fda.gov/medwatch" target="_blank">
                www.fda.gov/medwatch
              </a>
            </p>
          </Col>
        </Row>
      </ContentBlock>
    </div>
  );
};

const GetInTouchPage = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleForm = () => {
    setShowModal(true);
  };

  return (
    <Layout indication={siteIndications.hcp} className="hcp-get-in-touch">
      <Seo indication={siteIndications.hcp} pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <h1 id="rep">
              REQUEST A SPRIX<sup>&reg;</sup> (KETOROLAC TROMETHAMINE) SALES
              REPRESENTATIVE
            </h1>
            <h3 className="sub-headline">
              This is intended for US healthcare providers only. Please enter
              your contact information below.{" "}
            </h3>
            <h3 className="sub-headline smaller">
              *All required fields are marked with an asterisk.
            </h3>
          </ContentBlock>
          <div className="form-container">
            <ContentBlock>
              <FormContainer submittedForm={handleForm} />
            </ContentBlock>
            <SprixDirect />
            <ContactSection />
          </div>
          <ThankYouModal open={showModal} closeModal={handleClose} />
        </div>
      </div>
    </Layout>
  );
};

export default GetInTouchPage;
