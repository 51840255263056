import React from 'react';
import Modal from 'react-awesome-modal';

import './ThankYou.scss';

export const ThankYouModal = ({ open, closeModal }) => {
  return (
    <div className="thankyou-modal-container">
      <Modal visible={open} onClickAway={closeModal}>
        <div className="thankyou-container">
          <div className="thankyou-header">
            <div class="close" onClick={closeModal}>
              ×
            </div>
          </div>
          <div className="thankyou-body">
            <h1> Thank You for your request</h1>
          </div>
        </div>
      </Modal>
    </div>
  );
};
